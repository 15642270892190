<template>
  <div id="recordsEditPage">
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <messager
        :module="'price-records'"
        :thread="bidding.id"
        :header="bidding.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center mb-1">
        <b-col
          cols="12"
          md="11"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-card
                class="mb-1"
                no-body
              >
                <b-card-title class="m-0">
                  <div class="py-2 px-2 priceRecordsHeader">
                    <b-row class="align-items-center">
                      <b-col>
                        <span
                          class="d-inline recordsEditTitle"
                        >
                          {{ bidding.client_set.name }}
                        </span>
                        <b-button
                          id="btn-link-bidding"
                          type="button"
                          variant="outline-info"
                          class="mb-1 mb-sm-0 mr-0 p-0"
                          @click="goToBidding()"
                        >
                          <svg-icon
                            type="mdi"
                            :path="mdiLaunch"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                      <b-button
                        v-show="false"
                        class="filterBtn"
                        variant="outline-primary"
                      >
                        <svg-icon
                          type="mdi"
                          size="24"
                          :path="mdiFileExportOutline"
                        />
                        Exportar
                      </b-button>
                    </b-row>
                  </div>
                </b-card-title>
                <b-card-body class="p-0">
                  <b-row class="px-2 pt-2">
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <company-select
                        id="company"
                        v-model="bidding.company_set"
                        :readonly="true"
                        :required-field="true"
                        :truncate-field="17"
                        @ready="syncLoad.ready('company')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="trade_number"
                        :rules="{
                          required: true,
                          regex:/^([0-9]+)$/
                        }"
                      >
                        <b-form-group
                          label="Nº Pregão"
                          label-for="trade_number"
                        >
                          <b-form-input
                            id="trade_number"
                            v-model="bidding.trade_number"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            name="trade_number"
                            type="number"
                            :placeholder="isViewMode ? '' : 'Nº Pregão'"
                            :readonly="true"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="uasg"
                        :rules="{
                          required: true,
                          regex:/^([0-9]+)$/
                        }"
                      >
                        <b-form-group
                          label="UASG/OC"
                          label-for="uasg"
                        >
                          <b-form-input
                            id="uasg"
                            v-model="bidding.uasg"
                            :state="getValidationState(validationContext)"
                            name="uasg"
                            type="number"
                            :readonly="true"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="price_record_expiration_date"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-group
                          label="Validade do Registro"
                          label-for="price_record_expiration_date"
                        >
                          <b-form-datepicker
                            id="id_price_record_expiration_date"
                            v-model="bidding.price_record_expiration_date"
                            :required="true"
                            locale="pt-BR"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            size="md"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            label-no-date-selected="Nenhuma data foi selecionada"
                            selected-variant="primary"
                            :disabled="!hasPermissionToWrite || isViewMode"
                            placeholder="Selecione"
                            label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                            reset-button
                            reset-button-variant="info"
                            label-reset-button="Limpar"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        label="Status"
                        label-for="status"
                      >
                        <div
                          id="status"
                          class="form-control link-form-control priceRecordsStatus"
                          :readonly="true"
                        >
                          <b-badge
                            v-if="getCompanyStatus(bidding.price_record_expiration_date)"
                            pill
                            class="text-capitalize"
                            :style="{
                              'background-color': getCompanyStatus(bidding.price_record_expiration_date) === 'Vencido' ? '#E2445C' : '#219753'
                            }"
                          >
                            {{ getCompanyStatus(bidding.price_record_expiration_date) }}
                          </b-badge>
                          <span v-else>-</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                no-body
                class="mt-3"
              >
                <b-tabs
                  class="recordsTab"
                  card
                >
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.items"
                    @click="selectedTab(dataTab.items)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 recordsIconTitle"
                          type="mdi"
                          :path="mdiPackageVariant"
                        />
                        <span class="d-inline">Itens</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="pt-0 pb-2 px-0"
                    >
                      <price-records-items
                        :bidding="recordsId"
                        :readonly="isViewMode"
                        @ready="syncLoad.ready('items')"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                    :active="activeTab === dataTab.attachments"
                    @click="selectedTab(dataTab.attachments)"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 recordsIconTitle"
                          type="mdi"
                          :path="mdiPaperclip"
                        />
                        <span class="d-inline">Anexos</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="pt-0 pb-2 px-0"
                      no-body
                    >
                      <bidding-attachment
                        v-model="bidding.file_set"
                        :bidding-id="bidding.id"
                        :source-file="'price-records'"
                        :readonly="isViewMode"
                        :is-create-mode="isViewMode"
                        @ready="syncLoad.ready('attachment')"
                      />
                    </b-card-body>
                  </b-tab>
                </b-tabs>
              </b-card>
              <b-row class="mb-2">
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    v-if="!isViewMode"
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="disableEdition"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col class="d-flex justify-content-end buttonsSaveCancel">
                  <b-button
                    v-if="isViewMode"
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'records' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BTab,
  BTabs,
  BFormInput,
  BFormInvalidFeedback,
  BBadge,
} from 'bootstrap-vue'

import {
  mdiLaunch,
  mdiFileExportOutline,
  mdiPackageVariant,
  mdiPaperclip,
} from '@mdi/js'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import CompanySelect from '@/components/company/CompanySelect.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import Messager from '@/components/messager/Messager.vue'
import truncate from '@/components/utils/filters/truncate'
import BiddingAttachment from '@/views/bidding/components/BiddingAttachment.vue'
import PriceRecordsItems from '@/views/bidding/price-records/components/PriceRecordsItems.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BButton,
    BTab,
    BTabs,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormInput,
    BBadge,
    BFormInvalidFeedback,
    CompanySelect,
    SpinLoader,
    SvgIcon,
    Messager,
    BiddingAttachment,
    PriceRecordsItems,
  },
  directives: {
    Ripple,
  },
  filters: {
    truncate,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.bidding)

    let isViewMode = true

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    const bidding = {
      bidding: '',
      client_set: '',
      company_set: '',
      price_record_expiration_date: '',
      id: '',
      file_set: [],
    }

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/view\/.*/)) {
      bidding.id = router.currentRoute.params.id
    }

    const dataTab = {
      items: 1,
      attachments: 2,
    }

    const isLoaded = false

    const activeTab = router.currentRoute.params.tab || dataTab.bidding

    const recordsId = null

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('bidding')
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('messager')
    syncLoad.addTrigger('attachment')
    syncLoad.addTrigger('items')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      isViewMode,
      refFormObserver,
      getValidationState,
      bidding,
      recordsId,
      isLoaded,
      syncLoad,
      dataTab,
      activeTab,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
  },
  watch: {
    'bidding.price_record_expiration_date': function updatePriceRecordsDate(obj) {
      if (obj === '') {
        this.bidding.price_record_expiration_date = null
      }
    },
  },
  created() {
    const idToUpdate = router.currentRoute.params.id
    this.recordsId = router.currentRoute.params.id

    if (idToUpdate !== undefined) {
      syslic
        .bidding
        .fetchBidding(this.recordsId)
        .then(response => {
          this.bidding = response.data
          this.syncLoad.ready('bidding')
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('bidding')
        })
    } else {
      this.syncLoad.ready('bidding')
    }
  },
  methods: {
    selectedTab(value) {
      this.activeTab = value
    },
    hasValidityExpired(dateValidity) {
      if (!dateValidity) {
        return false
      }

      const today = moment().startOf('day')
      const validityDate = moment(dateValidity)

      return validityDate.isBefore(today)
    },
    getCompanyStatus(date) {
      if (!date) return false

      if (this.hasValidityExpired(date)) {
        return 'Vencido'
      }
      return 'Válido'
    },
    updateBidding() {
      syslic
        .bidding
        .updateBidding(this.bidding.id, this.bidding)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Registro atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o Registro.',
              text:
                'Não foi possível atualizar o registro, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      this.updateBidding()
    },
    enableEdition() {
      this.isViewMode = false

      router.push({ name: 'records-edit', params: { tab: this.activeTab } })
    },
    disableEdition() {
      router.push({
        name: 'records-view',
        params: {
          id: this.recordsId,
          tab: this.activeTab,
        },
      })

      this.isViewMode = true
    },
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.bidding.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  setup() {
    const toast = useToast()

    return {
      mdiLaunch,
      mdiFileExportOutline,
      mdiPackageVariant,
      mdiPaperclip,
      toast,
      moment,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#recordsEditPage {
  transform: none !important;
}

.priceRecordsHeader {
  background-color: $table-hover-bg;
  align-items: center;
}

.priceRecordsStatus {
  display: flex;
  align-items: center;
}

.recordsEditTitle {
  color: $primary;
  text-transform: uppercase;
}

input::placeholder {
  color: $text-muted !important;
}

#btn-link-bidding {
  border: 0px !important;
}

.recordsIconTitle {
  width: 21px;
  height: 21px;
}

.recordsTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.recordsTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.recordsTab .nav-link h4 {
  color: $body-color;
  font-size: 1rem;
}

.recordsTab .nav-link.active {
  background-color: #e2edf3;
}

.recordsTab .nav-link.active h4 {
  color: $primary;
}

.recordsTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
 .recordsTab .card-body {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
 }

 .recordsTab .tab-content {
   margin-top: -1rem;
 }

 .buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
